import { Layout, Menu, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { CloseOutlined } from '@ant-design/icons';
import './dashboard-layout.styles.less';
import lightLogo from '../../assets/rent/logo.png';
import UseViewport from './dashboard-viewport';
import CDrawer from '../../components/antd-custom/drawer/drawer';
import darkLogo from '../../assets/rent/logo-black.png';

const { Sider } = Layout;

const SideBar = ({ collapsed: showSidebar, activeMenu, setCollapsed: setShowSidebar }: any) => {
  const { mode } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const { width } = UseViewport();

  const NavItems = () => (
    <div>
      <Menu mode='inline' defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]}>
        <Menu.Item key='dashboard' icon={<i className='text-xl bx bx-category-alt'></i>}>
          <Link className='side-nav' to={`/`}>
            Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item key='company' icon={<i className='text-xl bx bx-user-pin'></i>}>
          <Link className='side-nav' to={`/company`}>
            Company
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );

  const isDark: boolean = useMemo(() => (mode === 'theme-mode-dark' ? true : false), [mode]);

  return (
    <React.Fragment>
      {width < 720 ? (
        <CDrawer
          closable={false}
          className='mobile-sidebar'
          title={
            <div className='flex justify-content-between align-items-center px-3'>
              <a href='/'>
                <img src={mode === 'theme-mode-light' ? lightLogo : darkLogo} alt='logo' style={{ width: 80, height: 60 }} />
              </a>
              <div onClick={() => setShowSidebar(!showSidebar)} style={{ backgroundColor: isDark ? 'var(--main-color)' : '#09263D', borderRadius: 5 }} className='flex justify-content-center align-items-center px-3 py-1 ml-1 cursor-pointer'>
                <Typography.Text className='text-sm text-white font-semibold'>
                  <CloseOutlined />
                </Typography.Text>
              </div>
            </div>
          }
          placement={'left'}
          onClose={() => setShowSidebar(!showSidebar)}
          visible={showSidebar}
          key={'left'}>
          <NavItems />
        </CDrawer>
      ) : (
        <Sider id='sidebar' className='sidebar shadow-1' collapsible collapsed={collapsed} onCollapse={(value: any) => setCollapsed(value)}>
          <NavItems />
        </Sider>
      )}
    </React.Fragment>
  );
};

export default SideBar;
