import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCompany, selectCurrentUser, selectPermission, selectMode, selectColor } from '../redux/authSlice'

export const useAuth = () => {
  const user: any = useSelector(selectCurrentUser);
  const permission: any = useSelector(selectPermission)
  const companyId: any = useSelector(selectCompany)
  const mode: any = useSelector(selectMode)
  const color: any = useSelector(selectColor)

  return useMemo(() => ({ user, permission, companyId, mode, color }), [user, permission, companyId, mode, color])
}

