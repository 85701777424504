import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute: FC<any> = () => {
  const { user } = useAuth();

  return user && user !== null ? <Outlet /> : <Navigate to='/signin' />;
};

export default PrivateRoute;
