import './App.less';
import { Route, Routes } from 'react-router-dom';
import { protectedRoutes, unProctedRoutes } from './routes';
import NotFound from './components/404/404.component';
import React from 'react';
import PrivateRoute from './components/PrivateRoute';
import { DashboardLayout } from './layouts/dashboard/dashboard-layout.component';

const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {unProctedRoutes?.map((route: any, index: number) => (
          <Route key={index} path={route?.path} element={route?.element} />
        ))}
        <Route
          element={
            <DashboardLayout>
              <PrivateRoute />
            </DashboardLayout>
          }>
          {protectedRoutes?.map((route: any, index: number) => (
            <Route key={index} path={route?.path} element={route?.element} />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default App;
