import './page.styles.less';

const PageOverlayLoader = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;

  return (
    <div className='fixed w-screen h-screen top-0 bottom-0 left-0 right-0' style={{ zIndex: 999999 }}>
      <div className='relative w-full h-full flex justify-content-center align-items-center'>
        <div className='spinner'>
          <div></div>
          <div></div>
        </div>

        {/* <div className='overlay absolute w-screen h-screen' style={{ background: '#000', opacity: 0.3 }}></div>
        <Spin size='large' className='z-20' /> */}
      </div>
    </div>
  );
};

export default PageOverlayLoader;
