import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type CompanyState = {
  page: number | undefined;
  perPage: number | undefined;
};

const slice = createSlice({
  name: 'company',
  initialState: { page: 1, perPage: 10 } as CompanyState,
  reducers: {
    setPginationData: (state, { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>) => {
      state.page = page;
      state.perPage = perPage;
    },
  },
});

export const { setPginationData } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: RootState) => state.company.page;
export const selectPageSize = (state: RootState) => state.company.perPage;
