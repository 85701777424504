import React, { Suspense } from 'react';
import PageOverlayLoader from './components/page-overlay-loader/page-overlay-loader.component';

const LazySigninPage = React.lazy(() => import('./pages/siginin/signin.component'));
const LazyErrorPage = React.lazy(() => import('./components/404/404.component'));
const LazyDashboardPage = React.lazy(() => import('./pages/dashboard/dashboard'));
const LazyCompanyPage = React.lazy(() => import('./pages/company/company'));
const LazyCompanyOperationPage = React.lazy(() => import('./pages/company/company.operation'));

export const unProctedRoutes: any[] = [
  {
    path: '/signin',
    key: 'signin_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazySigninPage />
      </Suspense>
    ),
  },
];

export const protectedRoutes: any[] = [
  {
    path: '/error',
    key: 'error_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyErrorPage />
      </Suspense>
    ),
  },
  {
    path: '/',
    key: 'dashboard_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyDashboardPage />
      </Suspense>
    ),
  },
  {
    path: '/company',
    key: 'company_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCompanyPage />
      </Suspense>
    ),
  },
  {
    path: '/companyOperation',
    key: 'company_add_operation_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCompanyOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/companyOperation/:companyId',
    key: 'company_edit_operation_page',
    element: (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCompanyOperationPage />
      </Suspense>
    ),
  },
];
