import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <Result
      status='404'
      title={<span className='custom-color'>{'404'}</span>}
      subTitle={<span className='custom-color'>{'Sorry, something went wrong.'}</span>}
      extra={
        <Button
          className='dark-btn'
          size='large'
          onClick={() => {
            navigate('/');
          }}>
          Back Home
        </Button>
      }
    />
  );
};

export default NotFound;
