import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../services/authApi';
import type { RootState } from './store';

type AuthState = {
  user: User | null;
  token: string | null;
  permission: any;
  companyId: any;
  mode?: string;
  color?: string;
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, permission: [], companyId: '', mode: '', color: '' } as AuthState,
  reducers: {
    setCredentials: (state, { payload: { user, token, permisssions, company_id } }: PayloadAction<{ user: User; token: string; permisssions?: any; company_id: number }>) => {
      state.user = user;
      //state.companyId = company_id ? company_id : '';
      state.token = token;
      // state.permission = permisssions;
      state.mode = 'theme-mode-light';
      state.color = 'theme-color-blue';
    },
    logout: (state) => {
      // state.permission = null;
      state.user = null;
      state.token = null;
      state.mode = '';
      state.color = '';
    },
    setMode: (state, { payload: { mode } }: PayloadAction<{ mode: string }>) => {
      state.mode = mode;
    },
    setColor: (state, { payload: { color } }: PayloadAction<{ color: string }>) => {
      state.color = color;
    },
  },
});

export const { setCredentials, logout, setMode, setColor } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectPermission = (state: RootState) => state.auth.permission;
export const selectCompany = (state: RootState) => state.auth.companyId;
export const selectMode = (state: RootState) => state.auth.mode;
export const selectColor = (state: RootState) => state.auth.color;
