import { Drawer } from 'antd';
import React from 'react';
import { useAuth } from '../../../hooks/useAuth';
import UseViewport from '../../../layouts/dashboard/dashboard-viewport';

interface IDrawer {
  onClose: any;
  visible: any;
  placement: any;
  closable: any;
  children: any;
  title?: any;
  key?: any;
  className?: string;
}
const CDrawer = ({ onClose, visible, children, placement, closable, key = 'cd', title = '', className = '' }: IDrawer) => {
  const { mode, color } = useAuth();
  const { width } = UseViewport();

  return (
    <Drawer className={`${mode} ${color} ${className}`} key={key} title={title} placement={placement} width={width <= 768 ? '100%' : '40%'} closable={closable} onClose={onClose} visible={visible}>
      {children}
    </Drawer>
  );
};

export default CDrawer;
