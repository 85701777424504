import { Layout, Menu, Dropdown, Button, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './dashboard-layout.styles.less';
import { useAuth } from '../../hooks/useAuth';
import { logout } from '../../redux/authSlice';
import { useAppDispatch } from '../../hooks/store';
import lightLogo from '../../assets/rent/logo.png';
import darkLogo from '../../assets/rent/logo-trans.png';
import UseViewport from './dashboard-viewport';
import SideBar from './dashboard.sidebar';

const { Header, Content } = Layout;

export const DashboardLayout: FC<{ children: any }> = ({ children }) => {
  const { mode, color } = useAuth();
  // let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState('dashboard');
  // const [showNotifications, setShowNotifications] = useState(false);
  // const [showCustomize, setShowCustomize] = useState(false);
  // const [currentNotification, setCurrentNotification] = useState(null);
  // const [notifications, setNotifications] = useState<any>([]);
  // const [showPalette, setShowPalette] = useState(false);

  // const { data, refetch, isSuccess } = useGetNotificationQuery({});

  // useEffect(() => {
  //   if (isSuccess) {
  //     data?.data?.length > 0 && setNotifications(data.data);
  //   }
  // }, [isSuccess, data]);

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  // let totalUnReadNotification: number = notifications?.length > 0 ? notifications?.length : 0;

  const toggleSidebar = () => {
    setCollapsed((c) => !c);
  };

  const { width } = UseViewport();

  useEffect(() => {
    if (width < 750) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
    const getActiveMenu = () => {
      const path = location.pathname;
      if (path.includes('company')) {
        return 'company';
      } else {
        return 'dashboard';
      }
    };
    const actMenu = getActiveMenu();
    setActiveMenu(actMenu);
  }, [location, width]);

  // useEffect(() => {
  //   const pusher = new Pusher('f5e67ac1367b7aad6617', {
  //     cluster: 'ap2',
  //   });
  //   const channel = pusher.subscribe('reminder');
  //   channel.bind('reminderMsg', (newMessage: any) => {
  //     if (newMessage?.setRemainder?.id) {
  //       refetch();
  //     }
  //   });
  //   return () => {
  //     channel.unbind_all();
  //     channel.unsubscribe();
  //   };
  // }, [refetch]);

  const logoutUser = () => {
    dispatch(logout());
  };

  const menu = (
    <Menu style={{ minWidth: 120 }}>
      <Menu.Item onClick={logoutUser}>Logout</Menu.Item>
    </Menu>
  );

  // const toggleNotifications = (notification: any, status: string) => {
  //   setShowNotifications((prevV) => !prevV);
  //   if (status === 'clicked') {
  //     setCurrentNotification(notification);
  //     setShowCustomize(true);
  //   }
  // };

  // const togglePalette = () => {
  //   setShowPalette(true);
  // };

  const isDark: boolean = useMemo(() => (mode === 'theme-mode-dark' ? true : false), [mode]);

  // const getWidth = () => {
  //   if (width >= 1600) return '25%';
  //   else if (width >= 1200) return '32%';
  //   else if (width >= 992) return '40%';
  //   else return '100%';
  // };
  // const NotificationDrawer = () => (
  //   <Drawer width={getWidth()} className={`notification-drawer ${mode} ${color}`} placement='right' closable={false} onClose={() => toggleNotifications('', '')} visible={showNotifications}>
  //     <div className='flex justify-content-between  align-items-center notification-header'>
  //       <Typography.Text className='text-head flex font-semibold text-base'>
  //         <span className='flex justify-content-end align-items-end custom-color'>Notifications</span>
  //       </Typography.Text>
  //       <div className='flex justify-content-between align-items-center'>
  //         <Badge color='#FF00A8'>
  //           <div style={{ backgroundColor: isDark ? 'var(--main-color)' : '#09263D', borderRadius: 5 }} className='flex justify-content-center align-items-center px-3 py-1 ml-2'>
  //             <Typography.Text className='text-sm text-white font-semibold'>{totalUnReadNotification}</Typography.Text>
  //           </div>
  //         </Badge>
  //         <div onClick={() => toggleNotifications('', '')} style={{ backgroundColor: isDark ? 'var(--main-color)' : '#09263D', borderRadius: 5 }} className='flex justify-content-center align-items-center px-3 py-1 ml-1 cursor-pointer'>
  //           <Typography.Text className='text-sm text-white font-semibold'>
  //             <CloseOutlined />
  //           </Typography.Text>
  //         </div>
  //       </div>
  //     </div>
  //     <Divider className='my-3' />
  //     {/* {notifications && notifications?.map((n: any) => <NotificationItem key={n.id} notification={n} closeNotification={() => toggleNotifications(n, 'clicked')} refetch={refetch} />)}
  //     {notifications?.length === 0 && <Empty description='No notifications to show' />} */}
  //   </Drawer>
  // );

  // const handleDocsClick = () => {
  //   window && window?.open('https://jocular-speculoos-01c5a6.netlify.app/', '_blank', 'noopener,noreferrer');
  //   // history?.push('/docs');
  // };

  return (
    <Layout className={`layout ${mode} ${color}`}>
      <Header className='app-header fixed px-5 z-1 w-full' style={{ padding: 0 }}>
        <div style={{ maxHeight: '64px' }} className='flex justify-content-between'>
          <div className='flex'>
            <div className='flex align-items-center -mt-1 md:mt-0'>
              <a href='/'>
                <img src={mode === 'theme-mode-light' ? lightLogo : darkLogo} alt='logo' style={{ width: isDark ? 55 : 80 }} />
              </a>
            </div>
            <div className='ml-4'>
              <Tooltip title={collapsed ? 'Hide Sidebar' : 'Show Sidebar'}>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: toggleSidebar,
                })}
                {width < 750 ? '' : <span className='ml-2'>{collapsed ? 'Hide Sidebar' : 'Show Sidebar'}</span>}
              </Tooltip>
            </div>
          </div>
          <div>
            <div className='flex align-items-center mt-2'>
              {/* <QuestionCircleOutlined onClick={handleDocsClick} className='cursor-pointer' style={{ fontSize: 30, color: 'gray' }} /> */}
              {/* {permission?.includes('listNotifications') && (
                <Button
                  className='mx-4 shadow-2 border-none'
                  shape='circle'
                  color='#FF30AC'
                  size='large'
                  icon={
                    <Badge offset={[10, -3]} count={totalUnReadNotification}>
                      <i className='fas fa-bell'></i>
                    </Badge>
                  }
                  onClick={() => toggleNotifications('', '')}
                />
              )} */}
              {/* <Button
                className='mr-3 shadow-2 border-none'
                shape='circle'
                size='large'
                icon={<i className='bx bxs-palette'></i>}
                  onClick={() => togglePalette()}
              /> */}
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className='mr-3 shadow-2 border-none' shape='circle' size='large' icon={<UserOutlined />} />
                {/* <div className='profile cursor-pointer'>{user?.profilePic ? <Avatar size='large' src={user?.profilePic} /> : <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />}</div> */}
              </Dropdown>
            </div>
          </div>
        </div>
      </Header>
      {/* <NotificationDrawer /> */}
      <Layout className={`site-layout ${collapsed ? 'side-nav-collapsed' : ''}`}>
        {collapsed && <SideBar collapsed={collapsed} setCollapsed={setCollapsed} activeMenu={activeMenu} />}
        <Content className='content-section mx-3 mb-3'>{children}</Content>
      </Layout>
    </Layout>
  );
};
